import { observer } from "mobx-react"
import DataTable from "../../../components/DataTable/DataTable"
import TABLE_COLUMNS from "../const/TABLE_COLUMNS"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { TransactionsViewModel } from "../TransactionsViewModel"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"
import { Transaction } from "../../../../domain/entities/Transaction"
import { Tooltip } from "antd"
import { Translation } from "../../../stores/TranslationStore"
import { Microsite } from "../../../../domain/entities/Microsite"
import { currencyFormatter } from "../../../utils/currencyFormatter"

interface TransactionTableProps {
	data: Transaction[]
	loading?: boolean
	isFetching?: boolean
	onChangePage?: (page: number, pageSize: number, lastPageFetched: number) => void
}

const TransactionsTable = ({ data, loading, isFetching, onChangePage }: TransactionTableProps) => {
	const viewModel = useContainerInjection<TransactionsViewModel>("TransactionsViewModel")
	const handlePageChange = (page: number, pageSize: number, lastPageFetched: number) => {
		viewModel.setTableConfig({ pageSize })
		onChangePage && onChangePage(page, pageSize, lastPageFetched)
	}
	const translation = useContainerInjection<Translation>("Translation")

	return (
		<DataTable
			loading={loading}
			tableConfig={{
				sort: viewModel.tableConfig.sort,
				pageSize: viewModel.tableConfig.pageSize
			}}
			isFetching={isFetching}
			renderCustomLoadingComponent={() => <DataTableRowsSkeleton size="lg" />}
			onChange={data => {
				data.sorter &&
					viewModel.setTableConfig({ sort: { order: data.sorter.order, field: data.sorter.field } })
			}}
			onChangePage={handlePageChange}
			columns={TABLE_COLUMNS()}
			dataSource={data.map((tran, idx) => {
				const transactionDate = new Date(tran.requestDate.replace("T", " ").replace("Z", ""))
				return {
					key: tran.id,
					id: tran.id,
					userEmail: tran.userEmail ?? "-",
					marketplace: tran.marketplace ?? "-",
					micrositeType:
						Microsite.businessTypes.find(type => type.id === tran.micrositeBusinessTypeId)?.label ?? "-",
					micrositeSlug: tran.micrositeSlug ?? "-",
					serviceName: (
						<div className="max-w-xs truncate">
							<Tooltip placement="topLeft" title={tran.serviceName}>
								{tran.serviceName}
							</Tooltip>
						</div>
					),
					sessionName: (
						<div className="max-w-xs truncate">
							<Tooltip placement="topLeft" title={tran.sessionName}>
								{tran.sessionName}
							</Tooltip>
						</div>
					),
					date: new Date(
						transactionDate.getFullYear(),
						transactionDate.getMonth(),
						transactionDate.getDate()
					).toLocaleDateString(translation.currentLang, {
						weekday: "short",
						year: "numeric",
						month: "short",
						day: "numeric"
					}),
					finalPrice: `${currencyFormatter(tran.paymentFinalAmount, tran.currency)}`,
					cancelledAt: tran.cancelledAt ? new Date(tran.cancelledAt).toLocaleString() : "-"
				}
			})}
		/>
	)
}

export default observer(TransactionsTable)
